<template>
    <div class="column content" v-if="data.templateContent && data.templateContent.templateContentFields">
        <router-link :to="data.templateContent.templateContentFields.overviewButtonUrl" class="inner-wrapper"
            v-if="data.templateContent.templateContentFields.overviewButtonUrl && data.templateContent.templateContentFields.overviewButtonUrl !== ''">
            <div class="thumb" v-if="data.templateContent.templateContentMedia &&
                data.templateContent.templateContentMedia
                    .mediaFirstImage &&
                data.templateContent.templateContentMedia
                    .mediaFirstImage !== ''
                ">
                <img :alt="data.templateContent.templateContentMedia.mediaFirstImage.title"
                    :src="data.templateContent.templateContentMedia.mediaFirstImage.url" />
            </div>
            <div class="content-wrapper">
                <h2 class="column-title" v-if="data.templateContent.templateContentFields.displayTitle !== '#'">
                    {{ data.templateContent.templateContentFields.displayTitle }}
                </h2>
                <div v-if="data.templateContent.templateContentFields
                    .contentFirst &&
                    data.templateContent.templateContentFields
                        .contentFirst !== ''
                    " v-html="parseHtml(data.templateContent.templateContentFields.contentFirst)">
                </div>
            </div>
        </router-link>
        <div class="inner-wrapper" v-else>
            <div class="thumb" v-if="data.templateContent.templateContentMedia &&
                data.templateContent.templateContentMedia
                    .mediaFirstImage &&
                data.templateContent.templateContentMedia
                    .mediaFirstImage !== ''
                ">
                <img :alt="data.templateContent.templateContentMedia.mediaFirstImage.title"
                    :src="data.templateContent.templateContentMedia.mediaFirstImage.url" />
            </div>
            <div class="content-wrapper">
                <h2 class="column-title" v-if="data.templateContent.templateContentFields.displayTitle !== '#'">
                    {{ data.templateContent.templateContentFields.displayTitle }}
                </h2>
                <div v-if="data.templateContent.templateContentFields
                    .contentFirst &&
                    data.templateContent.templateContentFields
                        .contentFirst !== ''
                    " v-html="parseHtml(data.templateContent.templateContentFields.contentFirst)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {

        };
    },
    components: {

    },
    mounted() {
        //console.log(this.data);
    },
    props: ['data'],
    inject: ["isAuthenticated"],
    methods: {
        parseHtml(passedHtml) {
            return passedHtml.replaceAll(
                'rel="noopener noreferrer" target="_blank"',
                ""
            );
        },
    }

};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>